<template>
  <div>
    <ul class="space-y-3">
      <template v-if="showMore">
        <li v-for="option in options" :key="option.code">
          <base-checkbox
            :name="name"
            :option="option"
            :value="value.includes(option.code)"
            @input="handleInput"
          />
        </li>
      </template>
      <template v-else>
        <li v-for="option in options.slice(0, 5)" :key="option.code">
          <base-checkbox
            :name="name"
            :option="option"
            :value="value.includes(option.code)"
            @input="handleInput"
          />
        </li>
      </template>
    </ul>
    <base-button
      class="mt-4"
      v-if="options.length > 5 && !showMore"
      @click.native="handleShowMore"
    >
      <svg
        class="mr-2"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 1V11M11 6L1 6"
          stroke="currentColor"
          stroke-width="1.6"
          stroke-linecap="round"
        />
      </svg>
      See More
    </base-button>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  components: {
    "base-checkbox": () => import("@/components/utils/Checkbox.vue"),
    "base-button": () => import("@/components/utils/Button.vue"),
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {
    handleInput(event) {
      const i = this.value.map((item) => item).indexOf(event);
      if (i >= 0) {
        this.value.splice(i, 1);
      } else {
        this.value.push(event);
      }
      this.$emit("input", this.value);
    },
    handleShowMore() {
      this.showMore = true;
    },
  },
};
</script>
